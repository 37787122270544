<template>
  <div class="content" ref="content">
    <div class="main-content">
      <div class="search-box" ref="search">
        <a-form>
          <a-row>
            <a-col :sm="6" :xxl="4">
              <e-date-time
                title="开始时间"
                :labelCol="8"
                :valueCol="14"
                v-model:value="queryParams.BeginDate"
              />
            </a-col>
            <a-col :sm="6" :xxl="4">
              <e-date-time
                title="结束时间"
                :labelCol="8"
                :valueCol="14"
                v-model:value="queryParams.EndDate"
              />
            </a-col>
            <a-col>
              <a-button type="primary" @click="getDataList(1)"> 查询 </a-button>
              <a-button
                type="primary"
                @click="reset(() => resetFun())"
                danger
                style="margin-left: 10px"
              >
                重置
              </a-button>
              <a-button
                type="primary"
                @click="exportExcel"
                style="margin-left: 10px"
              >
                导出Excel
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="data-list">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="false"
          :loading="loading"
          :scroll="{ y: height, x: width }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import {} from '@ant-design/icons-vue'
import { OrderItemClass } from '@/apis/order'
import page from '@/mixins/page'
import dayjs from 'dayjs'
import fileDownload from 'js-file-download'

const api = new OrderItemClass()
export default defineComponent({
  mixins: [page],
  components: {},
  setup () {
    const loading = ref(false)
    const height = ref(500)
    const width = ref(500)

    const queryParams = ref({})
    const columns = ref([])
    return {
      columns,
      loading,
      height,
      width,
      queryParams
    }
  },
  created () {
    this.getDataList(1)
  },
  methods: {
    getDataList (page) {
      this.loading = true
      this.page.page = page
      const q = { ...this.queryParams }

      if (q.BeginDate) {
        q.BeginDate = q.BeginDate.format('YYYY-MM-DD')
      }
      if (q.EndDate) {
        q.EndDate = q.EndDate.format('YYYY-MM-DD')
      }

      api.freeDishReport(q).then((resp) => {
        this.loading = false
        this.formatData(resp)
        this.getTableHeight()
      })
    },
    formatData (data) {
      const column = data[1]
      if (!column || column.length === 0) {
        this.list = []
        return
      }
      const columns = []
      for (var v in column) {
        columns.push({
          title: column[v],
          dataIndex: v,
          key: v,
          width: 120
        })
      }

      columns.map((v, k) => {
        if (k === 0) {
          v.width = 120
          v.resizable = true
        }

        if (k > 6) {
          v.width = 80
        }
      })

      this.columns = columns
      this.list = data.filter((v, k) => k > 1)
    },
    resetFun () {
      this.queryParams = {}
      this.getDataList(1)
    },

    getTableHeight () {
      const mainPadding = 30
      const thead = 56
      this.height =
        this.$refs.content.offsetHeight -
        this.$refs.search.offsetHeight -
        thead -
        9 -
        mainPadding

      this.width = this.$refs.search.offsetWidth - 10
    },

    exportExcel () {
      const q = { ...this.queryParams }

      if (q.BeginDate) {
        q.BeginDate = q.BeginDate.format('YYYY-MM-DD')
      }
      if (q.EndDate) {
        q.EndDate = q.EndDate.format('YYYY-MM-DD')
      }

      api.exportFreeDishReport(q).then((resp) => {
        fileDownload(
          resp,
          '赠菜统计' + dayjs().format('YYYYMMDDHHmmss') + '.xlsx'
        )
      })
    }
  }
})
</script>

<style lang="less" scoped>
.search-box {
  position: relative;
  z-index: 9;
}
</style>
